import { motion } from "framer-motion";

function Burbuja({ autor, id, mensaje }) {
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{ opacity: 1 }}
      style={{
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        background: "rgb(1 29 54)",
        margin: 7,
        borderRadius: 5,
        padding: 10,
        paddingLeft: 20,
        alignItems: "flex-start",
      }}
    >
      <strong>{autor}</strong>
      <span style={{ textAlign: "left", wordBreak: "break-word" }}>
        {mensaje}
      </span>
    </motion.div>
  );
}

export default Burbuja;
