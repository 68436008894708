import { Layout, message, Popconfirm, Space, Spin } from "antd";
import "antd/dist/antd.css";
import React from "react";
import Chat from "./componentes/chat/chat";
import ControlesStream from "./componentes/controlesStream";
import Escenario from "./componentes/escenario";
import {
  faBroadcastTower,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { store } from "../../store";
import { observer } from "mobx-react";
import EstructuraSiderAdmin from "./componentes/siderAdmin/estructuraSiderAdmin";
import { useHistory } from "react-router";

const { Footer, Sider, Content, Header } = Layout;

function EstructuraAuditorio() {
  const [collapsed, setCollapsed] = React.useState(true);
  const [collapsedChat, setCollapsedChat] = React.useState(false);
  const [startFinishEvento, setStartFinishEvento] = React.useState(false);
  const history = useHistory();
  const toggleLive = () => {
    setStartFinishEvento(true);
    if (!store.live) {
      // fetch url tipo post
      fetch(
        `https://mcu_${store.idEvento}.webinarclic.com/sala/${store.idEvento}/presentadores`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: store.idEvento,
          }),
        }
      )
        .then((r) => {
          console.log(r);
          return r.json();
        })
        .then((r) => {
          if (r) {
            store.live = true;
            message.success("Estas en VIVO");
            store.socketChat.emit("evento:iniciado", "iniciado");
            setStartFinishEvento(false);
          }
        });
    } else {
      fetch(`https://mcu_${store.idEvento}.webinarclic.com/terminarGrabacion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((r) => r.json())
        .then((r) => {
          if (r) {
            store.socketChat.emit("evento:finalizado", "finalizado");
            store.live = false;
            history.push("/eventoterminado");
          }
          setStartFinishEvento(false);
        });
    }
  };
  return (
    <div style={{ background: "#000" }}>
      <Layout style={{ height: "100vh" }}>
        {/* Opciones admin */}
        {store.tokenObjeto.tipo === "presentador" && (
          <Sider
            onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}
            collapsedWidth={50}
            collapsed={collapsed}
          >
            <EstructuraSiderAdmin siderCollapsed={collapsed} />
          </Sider>
        )}
        {/* Fin opciones admin */}
        {/* Contenido central */}
        <Layout>
          <Content>
            <Layout style={{ height: "100%", background: "#000" }}>
              <Header
                style={{
                  color: "#fff",
                  zIndex: 1,
                  background: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Space>
                  <Space
                    style={{
                      background: "rgb(26 26 26)",
                      borderRadius: 10,
                      padding: 10,
                      height: 25,
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <span>{store.cantidadAsistentes}</span>
                  </Space>
                  {!store.live && store.tokenObjeto.tipo !== "asistente" ? (
                    <>
                      <Space
                        style={{
                          background: "green",
                          borderRadius: 10,
                          padding: 10,
                          height: 25,
                        }}
                      >
                        <FontAwesomeIcon icon={faBroadcastTower} />
                        <span>Sin transmitir</span>
                      </Space>
                      {startFinishEvento ? (
                        <Spin style={{ color: "#fff" }} />
                      ) : (
                        <Popconfirm
                          title="¿Iniciamos transmisión?"
                          okType="danger"
                          okText="Iniciar ahora"
                          cancelText="No"
                          onConfirm={toggleLive}
                        >
                          <div className="iniciarTransmision">
                            <FontAwesomeIcon
                              style={{ fontSize: 16 }}
                              icon={faPowerOff}
                            />
                          </div>
                        </Popconfirm>
                      )}
                    </>
                  ) : (
                    <>
                      <Space
                        style={{
                          background: "red",
                          borderRadius: 10,
                          padding: 10,
                          height: 25,
                        }}
                      >
                        <FontAwesomeIcon icon={faBroadcastTower} />
                        <span>Transmitiendo</span>
                      </Space>
                      {startFinishEvento ? (
                        <Spin />
                      ) : (
                        store.tokenObjeto.tipo !== "asistente" &&
                        <Popconfirm
                          title="¿Terminar transmisión?"
                          okType="danger"
                          okText="Terminar ahora"
                          cancelText="No"
                          onConfirm={toggleLive}
                        >
                          <div className="TerminarTransmision">
                            <FontAwesomeIcon
                              style={{ fontSize: 16 }}
                              icon={faPowerOff}
                            />
                          </div>
                        </Popconfirm>
                      )}
                    </>
                  )}
                </Space>
              </Header>
              <Content
                style={{
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "#000810",
                }}
              >
                <Escenario />
              </Content>
              {/* Controles */}
              {store.tokenObjeto.tipo === "presentador" && (
                <Footer style={{ zIndex: 1, background: "#000810" }}>
                  {/* <ControlesStream /> */}
                </Footer>
              )}

              {/* Fin controles */}
            </Layout>
          </Content>
        </Layout>
        {/* fin contenido central */}
        {/* Chat */}
        <Sider
          onCollapse={() => setCollapsedChat(!collapsedChat)}
          collapsed={collapsedChat || !collapsed}
          width={300}
          reverseArrow
          collapsible
        >
          <Chat collapsedChat={collapsedChat} collapsed={collapsed} />
        </Sider>
        {/* fin chat */}
      </Layout>
    </div>
  );
}

export default observer(EstructuraAuditorio);
