import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faPowerOff,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { message, Popconfirm, Space } from "antd";
import {
  setStoreCamaraActiva,
  setStoreMicrofonoActivo,
  setStoreShareScreen,
  setStoreStreamPresentador,
  setStoreToogleScreen,
  store,
} from "../../../store";
import { observer } from "mobx-react";
import { useHistory } from "react-router";

function ControlesStream() {
  const [camaraActiva, setCamaraActiva] = React.useState(true);
  const [microfonoActivo, setMicrofonoactivo] = React.useState(true);
  const history = useHistory();
  const toggleLive = () => {
    if (!store.live) {
      store.socketStream.emit("start-event_uhsbadcs8uwjh", (r) => {
        console.log("Que dvule..");
        console.log(r);
        if (r) {
          store.live = true;
          message.success("Estas en VIVO");
        }
      });
    } else {
      store.socketStream.emit("terminar-evento-euhwjeknfcw", (r) => {});
      history.push("/eventoterminado");
    }
  };

  // capture screen
  const toggleScreen = async () => {
    if (store.shareScreen) {
      const streamVideo = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          width: {
            min: 1080,
            max: 1920,
          },
          height: {
            min: 720,
            max: 1080,
          },
        },
      });
      store.streamPresentador.getTracks().forEach((track) => {
        track.stop();
      });

      store.refPresentador.current.srcObject = streamVideo;

      // apagar la camara y el microfono
      setStoreStreamPresentador(streamVideo);
      const trackVideo = streamVideo.getVideoTracks()[0];
      const trackAudio = streamVideo.getAudioTracks()[0];

      await store.produceVideo.replaceTrack({
        track: trackVideo,
      });

      await store.produceAudio.replaceTrack({
        track: trackAudio,
      });

      streamVideo.getVideoTracks().forEach((track) => {
        track.enabled = store.camaraActiva;
      });

      setStoreShareScreen(false);
      store.socketStream.emit("stop-share-screen", store.produceVideo._id);
      return;
    }

    navigator.mediaDevices
      .getDisplayMedia({
        video: {
          cursor: "always",
        },
        audio: true,
      })
      .then(async (stream) => {
        // este es el track
        setStoreStreamPresentador(stream);
        store.refPresentador.current.srcObject = stream;
        store.produceVideo.replaceTrack({
          track: stream.getVideoTracks().find((t) => t.kind === "video"),
        });
        setStoreShareScreen(true);
        store.socketStream.emit("start-share-screen", store.produceVideo._id);

        // observer stream cuando finaliza la captura de pantalla
      });
  };

  React.useEffect(() => {
    if (store.shareScreen) {
      toggleScreen();
    }
  }, [store.toogleScreenShare]);

  // compartir pantall
  // aparagar o prender la camara
  const toggleCamara = () => {
    if (store.shareScreen) return;

    const tieneCamaraEncendida = store.streamPresentador
      .getTracks()
      .find((track) => track.enabled && track.kind === "video");

    if (tieneCamaraEncendida) {
      store.streamPresentador.getTracks().forEach((track) => {
        if (track.kind === "video") {
          track.enabled = false;
        }
      });
      setCamaraActiva(false);
      setStoreCamaraActiva(false);
    } else {
      store.streamPresentador.getTracks().forEach((track) => {
        if (track.kind === "video") {
          track.enabled = true;
        }
      });
      setCamaraActiva(true);
      setStoreCamaraActiva(true);
    }
  };

  // aparagar o prender el microfono
  const toggleMicrofono = () => {
    const tieneMicrofonoEncendido = store.streamPresentador
      .getTracks()
      .find((track) => track.enabled && track.kind === "audio");
    console.log(tieneMicrofonoEncendido);
    if (tieneMicrofonoEncendido) {
      store.streamPresentador.getTracks().forEach((track) => {
        if (track.kind === "audio") {
          track.enabled = false;
        }
      });
      setMicrofonoactivo(false);
      setStoreMicrofonoActivo(false);
    } else {
      store.streamPresentador.getTracks().forEach((track) => {
        if (track.kind === "audio") {
          track.enabled = true;
        }
      });
      setMicrofonoactivo(true);
      setStoreMicrofonoActivo(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Space size="large" style={{ display: "flex", flexDirection: "row" }}>
        <div style={botones} onClick={toggleCamara}>
          <FontAwesomeIcon
            style={{ fontSize: 20 }}
            icon={camaraActiva && !store.shareScreen ? faVideo : faVideoSlash}
          />
        </div>
        <div style={botones} onClick={toggleMicrofono}>
          <FontAwesomeIcon
            style={{ fontSize: 20 }}
            icon={microfonoActivo ? faMicrophoneAlt : faMicrophoneAltSlash}
          />
        </div>
        {!store.recibiendoScreen && (
          <div style={botones} onClick={toggleScreen}>
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faDesktop} />
          </div>
        )}
        {!store.live ? (
          <Popconfirm
            title="¿Iniciamos transmisión?"
            okType="danger"
            okText="Iniciar ahora"
            cancelText="No"
            onConfirm={toggleLive}
          >
            <div
              style={{
                ...botones,
                marginLeft: 10,
                background: "green",
                color: "#fff",
              }}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPowerOff} />
            </div>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="¿Terminar transmisión?"
            okType="danger"
            okText="Terminar ahora"
            cancelText="No"
            onConfirm={toggleLive}
          >
            <div
              style={{
                ...botones,
                marginLeft: 10,
                background: "red",
                color: "#fff",
              }}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPowerOff} />
            </div>
          </Popconfirm>
        )}
      </Space>
    </div>
  );
}

const botones = {
  background: "#fff",
  color: "#000",
  padding: 15,
  borderRadius: "50%",
  width: 50,
  height: 50,
  cursor: "pointer",
};

export default observer(ControlesStream);
