import { JitsiMeeting } from "@jitsi/web-sdk";
import { message } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import {
  setStoreLive,
  setStoreRecibiendoScreen,
  setStoreRefPresentador,
  store,
} from "../../../store";

function Escenario() {
  const miTransmision = React.useRef();
  const [streamPresentadores, setStreamPresentadores] = React.useState([]);
  const [prueba, setPrueba] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (store.tokenObjeto.tipo === "asistente") {
      const video = document.getElementById("video-stream");
      // agregar stream
      video.srcObject = store.streamPresentador;
    }
  }, []);

  React.useEffect(() => {
    if (store.tokenObjeto.tipo === "presentador") {
      return () => {};
    }
  }, [streamPresentadores, prueba]);

  return (
    <>
      <div
        className="contenedorVideos"
        style={{ background: "#000", display: "flex", flexGrow: "wrap" }}
      >
        {store.tokenObjeto.tipo === "presentador" && (
          <JitsiMeeting
            domain="presentadores.webinarclic.com"
            roomName={store.idEvento.toLowerCase()}
            configOverwrite={{
              startWithAudioMuted: true,
              startWithVideoMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: false,
              enableEmailInStats: false,
              disableSelfViewSettings: false,
              disableSelfView: false,
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              DISABLE_CHAT: true,
              DISABLE_CHAT_NOTIFICATIONS: true,
              DISABLE_CHAT_RESIZE: true,
              DISABLE_INITIAL_TOOLBAR_REMOVAL: true,
              DEFAULT_REMOTE_DISPLAY_NAME: "",
              DEFAULT_LOCAL_DISPLAY_NAME: "",
            }}
            userInfo={{
              displayName: ".",
              email: "",
              avatarURL: "",
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = 400;
            }}
          />
        )}
        {store.tokenObjeto.tipo === "asistente" && (
          <video autoPlay id="video-stream"></video>
        )}
      </div>
    </>
  );
}

export default observer(Escenario);
