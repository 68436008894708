import React from "react";
import { Route } from "react-router-dom";
import IndexAuditorio from "./paginas/auditorio/indexAuditorio";
import ErrorConexion from "./paginas/errorConexion";
import EventoTerminado from "./paginas/eventoTerminado";

function Router() {
  return (
    <>
      <Route
        exact
        path="/auditorio/:evento/:token?"
        component={IndexAuditorio}
      />
      <Route
        exact
        path="/eventoterminado"
        component={EventoTerminado}
      />
      <Route
        exact
        path="/errorconexion"
        component={ErrorConexion}
      />
    </>
  );
}

export default Router;
