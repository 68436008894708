function ErrorConexion() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <span style={{ fontSize: 20 }}>No pudimos conectarte al auditorio</span>
      <div
        style={{
          maxWidth: 300,
          marginTop: 10,
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <strong>Posibles razones:</strong>
        <span style={{ fontSize: 15 }}>* Este evento ya terminó</span>
        <span style={{ fontSize: 15 }}>* No tienes internet</span>
        <span style={{ fontSize: 15 }}>
          * No tienes permiso para ingresar a este evento
        </span>
        <span style={{ fontSize: 15 }}>
          * Puede que estes bajo un proxy o algún tipo de bloqueo en tu internet
          para este tipo de conexiones. Usa otra red
        </span>
      </div>
    </div>
  );
}

export default ErrorConexion;
