import { Button, Layout, message, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useHistory, useParams } from "react-router";
import {
  setStoreCamaraActiva,
  setStoreDevice,
  setStoreMicrofonoActivo,
  setStorePresentador,
  setStoreProduceAudio,
  setStoreProduceVideo,
  setStoreSalaConectada,
  setStoreSocketStream,
  setStoreStreamPresentador,
  setStoreTokenObjeto,
  setStoreTransportPresentadorEnviar,
  setStoreTransportPresentadorRecibir,
  storeToggleScreen,
  store,
  setStoreToogleScreenShare,
} from "../../../store";
import { io } from "socket.io-client";
import { Device } from "mediasoup-client";

let paramsEnc = {
  // mediasoup params
  encodings: [
    {
      rid: "r0",
      maxBitrate: 100000,
      scalabilityMode: "S1T3",
    },
    {
      rid: "r1",
      maxBitrate: 300000,
      scalabilityMode: "S1T3",
    },
    {
      rid: "r2",
      maxBitrate: 900000,
      scalabilityMode: "S1T3",
    },
  ],

  codecOptions: {
    videoGoogleStartBitrate: 2000,
  },
};

function ConectarSala() {
  const params = useParams();
  const [eventoIniciado, setEventoiniciado] = React.useState(true);
  const [etapa, setEtapa] = React.useState("Cargando configuraciones");
  const history = useHistory();
  const [cargada, setCargada] = React.useState(false);

  function entrarSala() {
    setStoreSalaConectada(true);
  }

  React.useEffect(() => {
    const token = localStorage.getItem(params.evento);
    const decoded = Buffer.from(token, "base64").toString("ascii");

    // convertimos el token a un objeto
    const tokenObj = JSON.parse(decoded);
    setStoreTokenObjeto(tokenObj);

    if (tokenObj.tipo === "presentador") {
      const socket = io(`${store.urlSocketStream}/mediasoup`, {
        rejectUnauthorized: false,
        timeout: 20000,
        reconnection: true,
        reconnectionAttempts: 100,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        query: {
          token: token,
        },
      });
      const device = new Device();
      socket.on("connect_error", function (e) {
        history.push("/errorconexion");
      });
      setEtapa("Verificando cámara y micrófono");
      setStorePresentador(true);

      //   acceder a la camara y el microfono
      async function accederaCamarayMicrofono() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
              width: {
                min: 1080,
                max: 1920,
              },
              height: {
                min: 720,
                max: 1080,
              },
            },
          });
          setStoreStreamPresentador(stream);

          setStoreCamaraActiva(true);
          setStoreMicrofonoActivo(true);
          setStoreStreamPresentador(stream);
          setEtapa("Conectando al auditorio");

          socket.on("connect", () => {
            setStoreSocketStream(socket);
            console.log("Estamos conectados al socket...");
            socket.emit("getRtpCapabilities", async (data) => {
              const capabilities = data.rtpCapabilities;
              try {
                setStoreDevice(device);
                await device.load({
                  routerRtpCapabilities: capabilities,
                });
                socket.emit(
                  "createWebRtcTransportPresentador",
                  async ({ params, paramsConsume }) => {
                    if (params.error) {
                      message.error(
                        "Algo no salio bien, intenta nuevamente o contacta con soporte"
                      );
                      return;
                    }
                    const transportPresentadorEnviar =
                      device.createSendTransport(params);
                    const transportPresentadorRecibir =
                      device.createRecvTransport(paramsConsume);

                    setStoreTransportPresentadorEnviar(
                      transportPresentadorEnviar
                    );
                    setStoreTransportPresentadorRecibir(
                      transportPresentadorRecibir
                    );

                    transportPresentadorRecibir.on(
                      "connect",
                      async ({ dtlsParameters }, callback, errback) => {
                        try {
                          socket.emit("transport-recv-connect", {
                            dtlsParameters,
                          });

                          callback();
                        } catch (error) {
                          console.log(error);
                          errback(error);
                        }
                      }
                    );

                    transportPresentadorEnviar.on(
                      "connect",
                      async ({ dtlsParameters }, callback, errback) => {
                        try {
                          socket.emit("transport-connect", {
                            dtlsParameters,
                          });

                          callback();
                        } catch (error) {
                          console.log(error);
                          errback(error);
                        }
                      }
                    );

                    transportPresentadorEnviar.on(
                      "produce",
                      async (parameters, callback, errback) => {
                        try {
                          socket.emit(
                            "transport-produce",
                            {
                              kind: parameters.kind,
                              rtpParameters: parameters.rtpParameters,
                              appData: parameters.appData,
                            },
                            ({ id }) => {
                              callback({ id });
                            }
                          );
                        } catch (error) {
                          console.log(error);
                          errback(error);
                        }
                      }
                    );
                    const produceVideo =
                      await transportPresentadorEnviar.produce({
                        track: stream
                          .getVideoTracks()
                          .find((track) => track.kind === "video"),
                        encodings: paramsEnc.encodings,
                        appData: { mediaTag: "cam-video" },
                      });

                    produceVideo.on("trackended", () => {
                      console.log("track ended");
                      if (store.shareScreen) {
                        setStoreToogleScreenShare(!store.toogleScreenShare);
                      }
                    });

                    produceVideo.on("transportclose", () => {
                      console.log("transport ended");
                    });

                    setStoreProduceVideo(produceVideo);

                    const produceAudio =
                      await transportPresentadorEnviar.produce({
                        track: stream.getAudioTracks().find((track) => {
                          return track.kind === "audio";
                        }),
                        // encodings: paramsEnc.encodings,
                        appData: { mediaTag: "cam-audio" },
                      });
                    produceAudio.on("trackended", () => {
                      console.log("track ended");
                    });

                    produceAudio.on("transportclose", () => {
                      console.log("transport ended");
                    });

                    setStoreProduceAudio(produceAudio);

                    setStoreSalaConectada(true);
                    return;
                  }
                );
              } catch (error) {
                if (error.name === "UnsupportedError")
                  message.error(
                    "Tu navegador no es compatible, puede intentar con otro."
                  );
              }
            });
          });
          socket.on("error", (err) => {
            message.error(
              "No pudimos conectarte con el auditorio, intenta nuevamente. Si el problema persiste contacta con soporte"
            );
            socket.on("getRtpCapabilities", (rtpCapabilities) => {
              console.log("rtpCapabilities", rtpCapabilities);
            });
          });
        } catch (error) {
          message.error("No se pudo acceder a la cámara y micrófono");
          console.log(error);
        }
      }
      accederaCamarayMicrofono();
    } else {
      var api = new window.apiMediaSP();

      api.urlWebSocket = `${store.urlSocketStream}/mediasoup`;
      api.token = token;

      api.evento("conection-success", (result) => {
        console.log("conection-success");
      });
      api.evento("connect_error", (result) => {
        console.log(result);
        console.log("conection-error");
      });

      api.evento("nuevo_stream", function (idUsuario, stream) {
        setStoreStreamPresentador(stream);
        setCargada(true);
      });

      api.evento("sin_iniciar", () => {
        console.log("Ajaaaaa sin iniciar esta cosa...");
        setEventoiniciado(false);
        setEtapa("Este evento aún no ha iniciado");
      });

      api.startAssitente();
    }
    setStoreTokenObjeto(tokenObj);
  }, []);

  return (
    <>
      <Layout>
        {cargada && store.tokenObjeto.tipo === "asistente" ? (
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 22 }}>El auditorio está</span>
            <span style={{ fontSize: 60, marginTop: -20 }}>LISTO</span>
            <Button onClick={entrarSala}>Entrar Ahora</Button>
          </Content>
        ) : (
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {eventoIniciado && (
              <>
                <Spin /> <span>{etapa}</span>
              </>
            )}

            {!eventoIniciado && (
              <div>
                <h1>Este evento aún no inicia</h1>
                <span>Espera que el presentador de inicio al evento.</span>
              </div>
            )}
          </Content>
        )}
      </Layout>
    </>
  );
}

export default ConectarSala;
