import React from "react";
import { Spin } from "antd";
import EstructuraAuditorio from "./estructuraAuditorio";
import { useParams, useHistory } from "react-router-dom";
import UrlErronea from "./componentes/urlErronea";
import { setStoreIdEvento, setStoreUrlSocketStream, store } from "../../store";
import ConectarSala from "./componentes/conectarSala";
import { observer } from "mobx-react";

function IndexAuditorio() {
  const [cargando, setCargando] = React.useState(true);
  const [urlErronea, setUrlErronea] = React.useState(false);
  const params = useParams();
  const history = useHistory();

  React.useEffect(() => {
    setStoreIdEvento(params.evento);
    setStoreUrlSocketStream(`https://ms_${params.evento}.webinarclic.com:2083`);
    if (params.token) {
      localStorage.setItem(params.evento, params.token);
      history.push(`/auditorio/${params.evento}`);
    } else {
      const token = localStorage.getItem(params.evento);
      if (!token) {
        setUrlErronea(true);
      }
    }
    setCargando(false);
  }, []);
  return (
    <>
      {cargando ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : urlErronea ? (
        <UrlErronea />
      ) : !store.salaConectada ? (
        <ConectarSala />
      ) : (
        <EstructuraAuditorio />
      )}
    </>
  );
}

export default observer(IndexAuditorio);
