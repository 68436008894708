import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";

function UrlErronea() {
  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        flexDirection: "row",
      }}
    >
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: 35 }}>Url incorrecta</h1>
        <span>La url parece estár mal escrita.</span>
        {/* salto de linea  */}
        <br />
        <span style={{ maxWidth: 300 }}>
          Por favor, revisa la url e intenta de nuevo. Si el problema persiste,
          por favor, contacta con soporte.
        </span>
      </Content>
    </Layout>
  );
}

export default UrlErronea;
