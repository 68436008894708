import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Layout } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { motion } from "framer-motion";

function EstructuraSiderAdmin({ siderCollapsed }) {
  return (
    <>
      {siderCollapsed ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon
            style={{ color: "#fff", marginBottom: 15, fontSize: 20 }}
            icon={faCog}
          />
        </motion.div>
      ) : (
        <>
          <motion.div
            style={{ height: "100%", background: "none" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Layout style={{ height: "100%", color: "#fff" }}>
              <Header style={{ color: "#fff" }}>
                {/* <span>Soy el header</span> */}
              </Header>
              <Content style={{ color: "#fff" }}>
                {/* <span>Soy el contenido</span> */}
              </Content>
              <Footer
                style={{
                  color: "#fff",
                  display: "flex",
                  padding: 0,
                  justifyContent: "center",
                }}
              ></Footer>
            </Layout>
          </motion.div>
        </>
      )}
    </>
  );
}

export default EstructuraSiderAdmin;
