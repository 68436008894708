import { observable, action } from "mobx";

const store = observable(
  {
    titulo: "Soy la sala",
    // urlSocketStream: "https://127.0.0.1:3000",
    urlSocketStream: "https://pruebawnclick.octopusmultinivel.com:2083",
    idEvento: "",
    device: null,
    transportPresentadorEnviar: null,
    transportPresentadorRecibir: null,
    produceVideo: null,
    produceAudio: null,
    recibiendoScreen: false,
    socketStream: null,
    socketChat: null,
    live: false,
    cantidadAsistentes: 0,
    toogleScreenShare: false,
    tokenObjeto: {},
    presentador: true,
    streamPresentador: null,
    refPresentador: null,
    shareScreen: false,
    camaraActiva: false,
    microfonoActivo: false,
    salaConectada: false,
  },
  {},
  { deep: true }
);



const setStoreCantidadAsistentes = action((val) => {
  store.cantidadAsistentes = val;
});

const setStoreSocketChat = action((val) => {
  store.socketChat = val;
});

const setStoreIdEvento = action((val) => {
  store.idEvento = val;
});

const setStoreTransportPresentadorEnviar = action((val) => {
  store.transportPresentadorEnviar = val;
});

const setStoreTransportPresentadorRecibir = action((val) => {
  store.transportPresentadorRecibir = val;
});

const setStoreSocketStream = action((val) => {
  store.socketStream = val;
});

const setStoreUrlSocketStream = action((val) => {
  store.urlSocketStream = val;
});

const setStoreDevice = action((val) => {
  store.device = val;
});

const setStoreTokenObjeto = action((val) => {
  store.tokenObjeto = val;
});

const setStorePresentador = action((val) => {
  store.presentador = val;
});

const setStoreRefPresentador = action((val) => {
  store.refPresentador = val;
});

const setStoreStreamPresentador = action((val) => {
  store.streamPresentador = val;
});

const setStoreShareScreen = action((val) => {
  store.shareScreen = val;
});

const setStoreCamaraActiva = action((val) => {
  store.camaraActiva = val;
});

const setStoreMicrofonoActivo = action((val) => {
  store.microfonoActivo = val;
});

const setStoreSalaConectada = action((val) => {
  store.salaConectada = val;
});

const setStoreProduceVideo = action((val) => {
  store.produceVideo = val;
});

const setStoreProduceAudio = action((val) => {
  store.produceAudio = val;
});

const setStoreToogleScreenShare = action((val) => {
  store.toogleScreenShare = val;
});

const setStoreRecibiendoScreen = action((val) => {
  store.recibiendoScreen = val;
});

const setStoreLive = action((val) => {
  store.live = val;
});

export {
  store,
  setStoreCantidadAsistentes,
  setStoreStreamPresentador,
  setStoreRefPresentador,
  setStoreShareScreen,
  setStoreCamaraActiva,
  setStoreMicrofonoActivo,
  setStoreSalaConectada,
  setStorePresentador,
  setStoreTokenObjeto,
  setStoreUrlSocketStream,
  setStoreSocketStream,
  setStoreDevice,
  setStoreTransportPresentadorEnviar,
  setStoreTransportPresentadorRecibir,
  setStoreProduceVideo,
  setStoreProduceAudio,
  setStoreToogleScreenShare,
  setStoreRecibiendoScreen,
  setStoreLive,
  setStoreIdEvento,
  setStoreSocketChat,
};
