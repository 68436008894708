import {
  faArrowAltCircleRight,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Layout, Input } from "antd";
import { motion } from "framer-motion";
import React from "react";
import "antd/dist/antd.css";
import Burbuja from "./burbuja";

import { io } from "socket.io-client";

import {
  setStoreCantidadAsistentes,
  setStoreLive,
  setStoreSocketChat,
  store,
} from "../../../../store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Footer, Sider, Content } = Layout;

function Chat({ collapsedChat, collapsed }) {
  const [autorizarEnvio, setAutorizarEnvio] = React.useState(true);
  const [chats, setChats] = React.useState([]);
  const [inputChat, setInputChat] = React.useState("");
  const [socketChat, setSocketChat] = React.useState(null);
  const history = useHistory();
  const mensajeNuevo = React.useCallback(
    (mensaje) => {
      setChats((chats) => {
        // ordenar chats por fecha
        var chatsOrdenados = [...chats, mensaje].sort((a, b) => {
          return new Date(a.fecha) - new Date(b.fecha);
        });
        // reduciro chats a 10
        chatsOrdenados = chatsOrdenados.slice(-20, chatsOrdenados.length);
        return chatsOrdenados.reverse();
      });
    },
    [setChats]
  );

  React.useEffect(() => {
    const ConSocketChat = io(
      `https://chat_${store.idEvento}.webinarclic.com`,
      // "http://localhost:3434",
      {
        rejectUnauthorized: false,
        timeout: 20000,
        reconnection: true,
        reconnectionAttempts: 100,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      }
    );
    setStoreSocketChat(ConSocketChat);
    setSocketChat(ConSocketChat);
    ConSocketChat.on("cantidadAsistentes", (cantidad) => {
      setStoreCantidadAsistentes(cantidad);
    });

    // verificar conexion
    ConSocketChat.on("connection", (socket) => {
      console.log(socket.id);
    });
    ConSocketChat.on("connect", () => {
      console.log("conectado");
    });
    ConSocketChat.on("disconnect", () => {
      console.log("desconectado");
    });
    ConSocketChat.on("error", (error) => {
      console.log("error", error);
    });
    ConSocketChat.on("chat:refrescarChats", (data) => {
      if (data) setChats(data);
    });
    ConSocketChat.on("chat:mensajenuevo", (data) => {
      mensajeNuevo(data);
    });
    ConSocketChat.on("evento:iniciado", (data) => {
      setStoreLive(true);
    });
    ConSocketChat.on("evento:finalizado", (data) => {
      setStoreLive(false);
      history.push("/eventoterminado");
      console.log("evento:finalizado", data);
    });

    ConSocketChat.on("evento:estado", (data) => {
      console.log("este evento esta: ", data);
      if (data === '"finalizado"' || data === "finalizado") {
        setStoreLive(false);
        history.push("/eventoterminado");
      } else if (data === '"iniciado"' || data === "iniciado") {
        setStoreLive(true);
      }
    });

    return () => {
      ConSocketChat.disconnect();
    };
  }, []);

  function enviarChat() {
    if (inputChat.length > 1 && autorizarEnvio && inputChat.length < 401) {
      const uidAleatorio = Math.random().toString(36).substring(2, 15);

      const msg = {
        mensaje: inputChat,
        autor: store.tokenObjeto.nombre,
        id: uidAleatorio,
        // fecha UTC
        fecha: new Date().toUTCString(),
      };

      mensajeNuevo(msg);
      socketChat.emit("chat:mensajenuevo", msg);

      setInputChat("");
      setAutorizarEnvio(false);
      setTimeout(() => {
        setAutorizarEnvio(true);
      }, 3000);
    }
  }
  return (
    <>
      {collapsedChat || !collapsed ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon
            style={{ color: "#fff", marginBottom: 15 }}
            size="2x"
            icon={faCommentAlt}
          />
        </motion.div>
      ) : (
        <>
          <Layout style={{ background: "none", height: "100%" }}>
            <Content
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                overflow: "auto",
              }}
            >
              {chats.map((e) => {
                return <Burbuja key={e.id} {...e} />;
              })}
            </Content>
            <Footer style={{ background: "none", margin: 0, padding: 0 }}>
              <Input
                maxLength={400}
                className="inputChat"
                placeholder="Animate, escribe algo"
                addonAfter={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
                onPressEnter={enviarChat}
                value={inputChat}
                onChange={(e) => setInputChat(e.currentTarget.value)}
              />
            </Footer>
          </Layout>
        </>
      )}
    </>
  );
}

export default Chat;
